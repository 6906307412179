.SpeedIndexByLocation_maps_1quuFvL {
  height: auto;
  width: 110%;
  stroke: #888;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  margin-top: -20px;
}

.SpeedIndexByLocation_location_31P6BI- {
  fill: #ddd !important;
  cursor: pointer;
  stroke: #fff
}

.SpeedIndexByLocation_location_31P6BI-:focus,
    .SpeedIndexByLocation_location_31P6BI-:hover {
  fill: #394EFF !important;
  outline: 0;
}

.SpeedIndexByLocation_heat_index0_3l5Bteo {
  fill:#ddd !important;
}

.SpeedIndexByLocation_heat_index5_1EWNK57 {
  fill: #3EAAAF !important;
}

.SpeedIndexByLocation_heat_index4_aXB7OYH {
  fill:#5FBABF !important;
}

.SpeedIndexByLocation_heat_index3_3yyqbHk {
  fill: #7BCBCF !important;
}

.SpeedIndexByLocation_heat_index2_2bLw7bQ {
  fill: #96DCDF !important;
}

.SpeedIndexByLocation_heat_index1_XECpRtd {
  fill: #ADDCDF !important;
}

.SpeedIndexByLocation_tooltip_2-X_p55 {
  position: fixed;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: white;
  font-size: 12px;
  line-height: 1.2;
}