.dropdownPlain_dropdown_3SdcBrP {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  padding: 4px 6px;
  border-radius: 3px;
  color: #333;
  font-weight: 500
}

.dropdownPlain_dropdown_3SdcBrP:hover {
  background-color: #ddd;
}

.dropdownPlain_dropdownTrigger_1kjxW8q {
  padding: 4px 8px;
  border-radius: 3px
}

.dropdownPlain_dropdownTrigger_1kjxW8q:hover {
  background-color: #ddd;
}

.dropdownPlain_dropdownIcon_3bz7m0Q {
  margin-top: 2px;
  margin-left: 3px;
}