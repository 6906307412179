.popMenu_wrapper_1D4YYcs  {
  position: relative;
  bottom: 0;
  right: 0;
}

.popMenu_menuItems_2nvNWMz {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  right: 6px;
  bottom: 70px;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end
}

.popMenu_menuItems_2nvNWMz .popMenu_menuItemButton_2BHvNbb {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-box-shadow: 0 2px 10px 0 #ddd;
          box-shadow: 0 2px 10px 0 #ddd;
  margin-top: 10px;
}

.popMenu_menuItems_2nvNWMz .popMenu_menuItemButton_2BHvNbb .popMenu_buttonLabel_2BJcMNY {
  opacity: 0;
  color: #394EFF;
  margin-right: 40px;
  white-space: nowrap;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

.popMenu_menuItems_2nvNWMz .popMenu_menuItemButton_2BHvNbb:hover {
  width: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  padding-left: 20px;
}

.popMenu_menuItems_2nvNWMz .popMenu_menuItemButton_2BHvNbb:hover .popMenu_buttonLabel_2BJcMNY {
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.popMenu_menuItems_2nvNWMz .popMenu_menuItemButton_2BHvNbb .popMenu_icon_-yPte_3 {
  position: absolute;
  right: 17px;
}

.popMenu_addStepButton_2HUC6kI {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #42AE5E;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
  transition: all 0.2s
}

.popMenu_addStepButton_2HUC6kI:hover {
  background-color: #2331A8;
  -webkit-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.popMenu_addStepButton_2HUC6kI .popMenu_plusIcon_2ZIl3bK {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, 0.2) );
          filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, 0.2) );
}

.popMenu_addStepButton_2HUC6kI.popMenu_openMenuBtn_p8vJ5eg {
  background-color: #333;
}

.popMenu_addStepButton_2HUC6kI.popMenu_openMenuBtn_p8vJ5eg .popMenu_plusIcon_2ZIl3bK {
  -webkit-transform: rotate(45deg) !important;
          transform: rotate(45deg) !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}