.discover_wrapper_3Csxe31 {
  position: relative;
}

.discover_button_pPBziez {
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 15px;
  height: 50px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s
}

.discover_button_pPBziez:hover {
  background-color: #f6f6f6;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.discover_button_pPBziez[data-active=true] {
  background-color: #f6f6f6;
}

.discover_modal_3CWAhzW {
  min-width: 350px;
  border-radius: 5px;
  background-color: white;
  -webkit-box-shadow: 0 2px 10px 0 #ddd;
          box-shadow: 0 2px 10px 0 #ddd;
  position: absolute;
  right: -40px;
  top: 65px
}

.discover_modal_3CWAhzW .discover_tooltipArrow_31Kv1Lh {
  width: 50px;
  height: 25px;
  position: absolute;
  bottom: 100%;
  right: 16px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  overflow: hidden;
}

.discover_modal_3CWAhzW .discover_tooltipArrow_31Kv1Lh::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: white;
  -webkit-transform: translateX(-50%) translateY(50%) rotate(45deg);
          transform: translateX(-50%) translateY(50%) rotate(45deg);
  bottom: 0;
  left: 50%;
  -webkit-box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.6);
          box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.6);
}

.discover_modal_3CWAhzW .discover_header_3c43tdJ {
  padding: 10px 15px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.discover_modal_3CWAhzW .discover_header_3c43tdJ .discover_info_h0P-aGf > div:first-child {
  font-style: italic;
  font-weight: 400;
}

.discover_modal_3CWAhzW .discover_header_3c43tdJ .discover_info_h0P-aGf > div:last-child {
  font-size: 12px;
  font-weight: 300;
}

.discover_content_1dhSxL0 h4 {
  border-bottom: solid thin #ddd;
  padding: 10px 20px;
}

.discover_content_1dhSxL0 .discover_list_2YZJHIN {
  padding: 20px;
  padding-top: 0;
}

.discover_progressWrapper_1UsKKt6 {
  width: 25px;
}