.timezoneDropdown_dropdown_5wOX7ek {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  padding: 4px 6px;
  border-radius: 3px;
  color: #333;
  font-weight: 500
}

.timezoneDropdown_dropdown_5wOX7ek:hover {
  background-color: #ddd;
}

.timezoneDropdown_dropdownTrigger_Fark4nR {
  padding: 4px 8px;
  border-radius: 3px
}

.timezoneDropdown_dropdownTrigger_Fark4nR:hover {
  background-color: #ddd;
}

.timezoneDropdown_dropdownIcon_2LJ9mUE {
  margin-top: 2px;
  margin-left: 3px;
}