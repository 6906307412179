.savedSearchList_header_3fspM8a {
  margin-bottom: 15px
}

.savedSearchList_header_3fspM8a .savedSearchList_label_RuMkEfn {
  text-transform: uppercase;
  color: gray;
  letter-spacing: 0.2em;
}

.savedSearchList_showMore_7a1IUeZ:hover {
  color: #394EFF
}

.savedSearchList_showMore_7a1IUeZ:hover svg {
  fill: #394EFF;
}

.savedSearchList_showMore_7a1IUeZ:hover .savedSearchList_actions_2B6Gfyf {
  opacity: 1;
}