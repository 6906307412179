.sessionLine_wrapper_UVAmd1S {
  padding: 12px 0 8px;
  margin: 0 5px;
  border-bottom: 1px solid #ddd;
}

.sessionLine_info_3CZEuZX {
  font-size: 15px;
}

.sessionLine_subInfo_2aj_8be {
  color: #888;
  font-size: 12px;
}

.sessionLine_link_CG3IUWD {
  position: absolute;
  right: 0;
  opacity: 0;
}

.sessionLine_wrapper_UVAmd1S:hover .sessionLine_link_CG3IUWD {
  opacity: 1;
}

.sessionLine_ellipsisWrapper_34l9Ymr {
  overflow: hidden;
}