.FilterModal_wrapper_3K2ToZv {
  border-radius: 3px;
  border: solid thin #ddd;
  padding: 20px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-box-shadow: 0 2px 2px 0 #ddd;
          box-shadow: 0 2px 2px 0 #ddd;
}

.FilterModal_optionItem_1-KzRFG {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
}

.FilterModal_optionItem_1-KzRFG:hover {
  background-color: #F6F7FF;
  color: #394EFF !important;
}

.FilterModal_optionItem_1-KzRFG:hover svg {
  fill: #394EFF !important;
}

.FilterModal_filterSearchItem_2vbTxLQ:hover {
  background-color: #F6F7FF;
  color: #394EFF;
}

.FilterModal_filterSearchItem_2vbTxLQ:hover svg {
  fill: #394EFF;
}

.FilterModal_filterSearchItem_2vbTxLQ div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}