.featureItem_wrapper_259z1Bl {
  padding: 6px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.featureItem_checkbox_1tgnV7N {
  font-weight: 500;
  pointer-events: none
}

.featureItem_checkbox_1tgnV7N label:before {
  border-radius: 50% !important;
  background-color: #ddd !important;
}

.featureItem_checkbox_1tgnV7N.featureItem_active_2H6f46A {
  text-decoration: line-through !important;
  font-weight: 300;
}

.featureItem_checkbox_1tgnV7N.featureItem_active_2H6f46A label:before {
  display: none !important;
}

.featureItem_subText_H9Zkmzw {
  margin-left: 27px;
  color: #888;
  font-size: 12px;
  font-weight: 300;
}

.featureItem_activeLink_1iUoQnl {
  cursor: pointer;
  pointer-events: default;
  text-decoration: underline
}

.featureItem_activeLink_1iUoQnl label {
  color: #000000 !important;
  text-decoration: underline;
}

.featureItem_completed_hYEHea8 {
  pointer-events: none
}

.featureItem_completed_hYEHea8 label {
  text-decoration: none !important;
}