.Bar_bar_2blWabE {
  height: 5px;
  width: 100%;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.Bar_bar_2blWabE div {
  padding: 0 5px;
  height: 20px;
  color: #FFF;
}

.Bar_bar_2blWabE div:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.Bar_bar_2blWabE div:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}