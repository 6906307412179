.button_button_2rl5k6p {
  background-color: white !important;
  border-radius: 3px !important;
  font-family: 'Roboto' !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  height: 38px
}

.button_button_2rl5k6p.button_medium_3T6SxPA  {
  height: 38px;
}

/* .small {
  height: 33px !important;
}

.smallest {
  height: 26px !important;
  font-size: 12px !important;
} */

.button_primary_2dTnTVo {
  background-color: #394EFF !important;
  -webkit-box-shadow: 0 0 0 1px #394EFF inset !important;
          box-shadow: 0 0 0 1px #394EFF inset !important;
  color: #FFF !important;
  -webkit-transition: all 0.2s;
  transition: all 0.2s
}

.button_primary_2dTnTVo:hover {
  background-color: #2331A8 !important;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-box-shadow: 0 0 0 1px #2331A8 inset !important;
          box-shadow: 0 0 0 1px #2331A8 inset !important;
}

.button_outline_2i2l28b {
  background-color: white !important;
  -webkit-box-shadow: 0 0 0 1px #394EFF inset !important;
          box-shadow: 0 0 0 1px #394EFF inset !important;
  color: #394EFF !important;
  -webkit-transition: all 0.2s;
  transition: all 0.2s
}

.button_outline_2i2l28b:hover {
  background-color: #F6F7FF !important;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.button_outline_2i2l28b.button_disabled_K3xACei {
  color: red !important;
}

.button_plain_2pVBxaE {
  background-color: transparent !important;
  color: #394EFF !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  padding: 0 10px !important
}

.button_plain_2pVBxaE:hover {
  background-color: transparent !important;
  color: #2331A8 !important;
}

.button_plain_2pVBxaE.button_hover_1x2jTvw:hover {
  background-color: #ddd !important;
}

.button_margin-right_IeYLZmA {
  margin-right: 10px !important;
}

.button_no-padding_1N3IVY5 {
  padding: 0 !important;
  margin: 0 !important;
}

.button_success_Lk8I4VQ {
  background-color: #E9F9ED !important;
  color: #21BA45 !important;
  border: solid thin #21BA45 !important;
}

.button_error_qTH6pQp {
  background-color: #FFF2F2 !important;
  color: #CC0000 !important;
  border: solid thin red !important;
}