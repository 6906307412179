.header_header_27gvRlR {
  position: fixed;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: solid thin #ddd;
  padding: 0 15px;
  background: #fff;
  z-index: 100;
}

.header_nav_1rhxP6w {
  position: relative;
  margin: 0 15px;
  padding: 0 10px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  text-transform: uppercase;
  white-space: nowrap;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.header_nav_1rhxP6w:hover, .header_nav_1rhxP6w.header_active_3_n3a5x {
  color: #394EFF;
  border-bottom: 2px solid #394EFF;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.header_nav_1rhxP6w {
  position: relative;
}

.header_logo_11R6T0C {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='UTF-8'?%3E %3Csvg viewBox='0 0 52 59' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E %3Ctitle%3EGroup%3C/title%3E %3Cg id='logos' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E %3Cg id='1' transform='translate(-107.000000, -142.000000)' fill-rule='nonzero'%3E %3Cg id='Group' transform='translate(107.000000, 142.000000)'%3E %3Cpath d='M44.2286654,29.5 L6.50039175,7.42000842 L6.50039175,51.5799916 L44.2286654,29.5 Z M49.3769757,24.9357962 C50.9991976,25.8727671 52,27.6142173 52,29.5 C52,31.3857827 50.9991976,33.1272329 49.3769757,34.0642038 L8.01498302,58.2754687 C4.63477932,60.2559134 0,57.9934848 0,53.7112649 L0,5.2887351 C0,1.00651517 4.63477932,-1.25591343 8.01498302,0.724531317 L49.3769757,24.9357962 Z' id='outline' fill='%23394EFF'/%3E %3Cpath d='M29.4155818,28.4568548 L14.7929806,20.1454193 C14.2168086,19.8179252 13.4842425,20.0195184 13.1567483,20.5956904 C13.0540138,20.7764349 13,20.9807697 13,21.188671 L13,37.8115419 C13,38.4742836 13.5372583,39.0115419 14.2,39.0115419 C14.4079013,39.0115419 14.6122361,38.9575281 14.7929806,38.8547936 L29.4155818,30.5433581 C29.9917538,30.215864 30.193347,29.4832978 29.8658528,28.9071259 C29.7590506,28.7192249 29.6034827,28.563657 29.4155818,28.4568548 Z' id='inner-play' fill='%2327A2A8'/%3E %3C/g%3E %3C/g%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  height: 50px;
  width: 30px;
  margin-right: 15px;
}

.header_right_2hKYOiL {
  margin-left: auto;
  position: relative;
  cursor: default;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.header_right_2hKYOiL > .header_menuItem_3rqFZLd {
  height: 50px;
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-left: solid thin #ddd;
  position: relative;
}

.header_right_2hKYOiL > .header_menuItem_3rqFZLd .header_dropdown_3pK23xL {
  position: absolute;
  right: 0;
  top: 50px;
}

.header_userDetails_2892wji {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  position: relative;
  padding: 0 5px 0 15px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  min-width: 100px
}

.header_userDetails_2892wji:hover {
  background-color: #f6f6f6;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.header_userDetails_2892wji:hover ul {
  display: block;
}

.header_userDetails_2892wji ul {
  display: none;
  position: absolute;
  list-style: none;
  right: -15px;
  top: 50px;
  left: -1px;
  background: #fff;
  z-index: 1;
}

.header_userDetails_2892wji li {
  border: 1px solid #ddd;
  border-top: none;
}

.header_userDetails_2892wji li:first-child {
  border-top: 1px solid #ddd;
}

.header_userDetails_2892wji a, .header_userDetails_2892wji button {
  color: #333;
  display: block;
  cursor: pointer;
  width: 100%;
  padding: 10px 15px;
  text-align: left;
  font-size: 14px;
}

.header_userDetails_2892wji a:hover, .header_userDetails_2892wji button:hover {
  background-color: #f6f6f6;
}

.header_userIcon_3Ara-7r {
  width: ;
  height: ;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-person' viewBox='0 0 16 16' fill='%23666'%3E %3Cpath d='M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.header_headerIcon_3RJsjHi {
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 15px;
  height: 50px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s
}

.header_headerIcon_3RJsjHi:hover {
  background-color: #f6f6f6;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.header_headerIcon_3RJsjHi[data-active=true] {
  background-color: #f6f6f6;
}

.header_divider_2XPAvqw {
  width: 1px;
  background-color: #ddd;
}

.header_placeOnTop_3WnDWER {
  z-index: 9999;
}

.header_newBadge_1zfdXT5 {
  position: absolute;
  height: 14px;
  width: 28px;
  background-image: linear-gradient(40deg, #6051FF 0%, #FF693B 100%);
  border-radius: 3px;
  font-size: 9px;
  line-height: 15px;
  color: white;
  text-align: center;
  right: -22px;
  top: 10px;
}