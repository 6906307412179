.escapeButton_closeWrapper_2s92jHj {
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 23px;
  right: 20px;
  top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 102; /* stay top of the test builer stiky header */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  opacity: 0.5
}

.escapeButton_closeWrapper_2s92jHj:hover {
  opacity: 1
}

.escapeButton_closeWrapper_2s92jHj div:last-child {
  font-size: 8px;
  line-height: 12px;
}