.widgetHOC_circle_ujZ5swh {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}

.widgetHOC_panel_38nMF3D {
  border-radius: 5px;
  border: solid thin #EEEEEE;
  background: #fff;
  padding: 15px
}

.widgetHOC_panel_38nMF3D:hover .widgetHOC_closeButton_3d5SerX {
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.widgetHOC_panel_38nMF3D.widgetHOC_fullwidth_sDfec0y {
  width: 100%;
}

.widgetHOC_panel_38nMF3D.widgetHOC_minHeight_3EamWFi {
  height: 300px;
}

.widgetHOC_panel_38nMF3D.widgetHOC_fitContent_1yFAEge {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.widgetHOC_panel_38nMF3D .widgetHOC_closeButton_3d5SerX {
  opacity: 0;
}

.widgetHOC_panel_38nMF3D .widgetHOC_trendChart_3aPAMto {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -15px;
}