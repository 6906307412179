.tabs_tabs_2fm9BaI {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.tabs_tabs_2fm9BaI.tabs_bordered_3-1Ufyp {
  border-bottom: solid thin #ddd;
}

.tabs_tab_3n-3A9K {
  padding: 10px 15px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  color: #333;
  border-bottom: solid thin transparent;
  margin-bottom: -1px;
  font-weight: 500;
  white-space: nowrap
}

.tabs_tab_3n-3A9K:hover {
  color: #394EFF;
}

.tabs_tab_3n-3A9K.tabs_active_3Mqc4Ib {
  color: #394EFF;
  border-bottom: solid thin #394EFF;
}

.tabs_disabled_2Uuz-r6 {
  pointer-events: none;
  opacity: 0.5;
}