.errorFrame_rawLine_13vGyKv {
  margin-left: 30px;
  font-family: 'Menlo', 'monaco', 'consolas', monospace;
  font-size: 13px;
}

.errorFrame_formatted_3zbM57Q {
  border: solid thin #EEE;
  border-radius: 3px;
}

.errorFrame_header_Q4ZRdPc {
  background-color: #f6f6f6;
  padding: 8px;
  border-bottom: solid thin #EEE;
}

.errorFrame_content_2F3Y3aS {
  font-family: 'Menlo', 'monaco', 'consolas', monospace;
  list-style-position: inside;
  list-style-type: decimal-leading-zero;
}

.errorFrame_errorLine_1U2DVpF {
  background-color: #394EFF;
  color: white !important;
  font-weight: bold;
}