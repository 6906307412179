* {
  margin: 0;
  padding: 0;
  border-radius: 0;
  text-decoration: none;
  outline: none;
}

body, html {
  height: 100%;
  background: #f6f6f6;
  font-family: 'Roboto', 'ArialMT', 'Arial';
  font-size: 14px;
  font-weight: 400;
  color: #333;
}

input, button {
  background: none;
  border: none;
  border: solid thin transparent;
}

button {
  font-weight: 400;
}

textarea:focus,
input:focus {
  border: solid thin #394EFF !important;
}

body ::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

h1, h2, h3, h4, h5 {
  font-family: 'Roboto', 'ArialMT', 'Arial';
  font-weight: 500;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:focus {
  outline: none;
}
