.LiveFilterModal_wrapper_DrHXAdg {
  border-radius: 3px;
  border: solid thin #ddd;
  padding: 20px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-box-shadow: 0 2px 2px 0 #ddd;
          box-shadow: 0 2px 2px 0 #ddd;
}

.LiveFilterModal_optionItem_1HsXYeO {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
}

.LiveFilterModal_optionItem_1HsXYeO:hover {
  background-color: #F6F7FF;
  color: #394EFF !important;
}

.LiveFilterModal_optionItem_1HsXYeO:hover svg {
  fill: #394EFF !important;
}

.LiveFilterModal_filterSearchItem_2u-j3nz:hover {
  background-color: #F6F7FF;
  color: #394EFF;
}

.LiveFilterModal_filterSearchItem_2u-j3nz:hover svg {
  fill: #394EFF;
}

.LiveFilterModal_filterSearchItem_2u-j3nz div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}