.information_wrapper_1m9SUbz {
  position: fixed;
  background-color: white;
  top: 50px;
  left: 0;
  right: 0;
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: solid thin #EEEEEE;
}

.information_primary_1ny56ak {
  background-color: #394EFF;
  color: white;
}
