.tagList_tagList_j0mEq8q {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: -8px;
  min-height: 40px
}

.tagList_tagList_j0mEq8q > div {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}