.textLabel_sessionLabel_EuTx7VE {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px
}

.textLabel_sessionLabel_EuTx7VE > div:last-child {
  min-height: 16px;
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
