.icon_wrapper_2jeksFN {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center
}

.icon_wrapper_2jeksFN[data-inline=true] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
