.label_label_1tNxOnN {
  font-weight: 500 !important
}

.label_label_1tNxOnN > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.label_label_1tNxOnN[data-rounded] {
  border-radius: 16px;
}

.label_label_1tNxOnN[data-red = true] {
  color: #cc0000 !important;
  background-color: rgba(204, 0, 0, 0.1);
}

.label_label_1tNxOnN[data-green = true] {
  color: #12722A !important;
  background-color: rgba(60, 180, 0, 0.1);
}