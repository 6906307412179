.title_title_368qtlY {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.title_title_368qtlY h4 {
  margin: 0;
  padding-right: 10px;
}

.title_title_368qtlY > span {
  font-size: 14px;
  color: #888;
}
