.countBadge_title_33DhUgy {
  color: #888;
  font-size: 11px;
  margin-bottom: 15px;
}

.countBadge_icon_2yvr7f4 {
  height: 16px;
}

.countBadge_countWrapper_HsrAKXG {
  height: 24px;
  margin-top: -5px;
}

.countBadge_count_Eedtz8t {
  font-size: 20px;
  font-weight: 500;
}

.countBadge_unit_30QVxmG {
  font-size: 15px;
  -ms-flex-item-align: end;
      align-self: flex-end;
  margin-left: 5px;
  margin-bottom: 3px;
}

.countBadge_change_3xlKTV7 {
  font-size: 12px;
  min-height: 20px
}

.countBadge_change_3xlKTV7 > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.countBadge_change_3xlKTV7[data-colorgreen=true] {
  color: #42AE5E;
}

.countBadge_change_3xlKTV7[data-colorgreen=false] {
  color: #cc0000;
}