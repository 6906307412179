.table_header_R1WCiw8 {
  padding: 10px 0;
  font-weight: 500;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.table_topBorder_25SJQfT {
  height: 1px;
  background-color: #EEEEEE;
  margin-top: 6px;
  width: 100%;
}

.table_content_1wGHHo4 {
  overflow-y: auto;
  max-height: 340px
}

.table_content_1wGHHo4.table_small_2ZgJYFh {
  height: 201px;
}

.table_row_V432uNh {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #EFEFEF;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 54px;
  font-size: 13px
}

.table_row_V432uNh .table_cell_2CdOOQG {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding-right: 10px;
}

.table_row_V432uNh:hover {
  background-color: #f6f6f6;
}
