.tagBadge_badge_2HPSwmr {
  padding: 2px 10px;
  border-radius: 15px;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 13px;
  height: 29px;
  margin-right: 8px;
  font-weight: 300;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-transform: capitalize;
  color: #666 !important
}

.tagBadge_badge_2HPSwmr[data-outline=true] {
  -webkit-box-shadow: 0 0 0 1px #ddd inset;
          box-shadow: 0 0 0 1px #ddd inset;
  backgorund-color: white;
}

.tagBadge_badge_2HPSwmr:hover {
  background-color: #F6F7FF;
}

.tagBadge_badge_2HPSwmr button {
  margin-left: 2px;
  margin-right: -5px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 18px;
  height: 18px
}

.tagBadge_badge_2HPSwmr button .tagBadge_closeIcon_33zaaSl {
  width: 13px;
  height: 13px;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-x-lg' viewBox='0 0 16 16' fill='%23888'%3E %3Cpath fill-rule='evenodd' d='M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z'/%3E %3Cpath fill-rule='evenodd' d='M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.tagBadge_badge_2HPSwmr[data-hashed=true]:before {
  content: '#';
  margin-right: 1px;
}