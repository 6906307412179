.react-datepicker {
  -webkit-box-shadow: 0px 1px 3px 0 #ddd;
          box-shadow: 0px 1px 3px 0 #ddd;
}

.react-datepicker__triangle,
.react-datepicker__header {
  /* background-color: white; */
}

.react-datepicker__day--selected:hover {
  background-color: #2331A8;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected {
  background-color: #394EFF;
}