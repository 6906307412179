.ModalOverlay_overlay_2mQqAGV {
  /* absolute w-full h-screen cursor-pointer */
  position: absolute;
  width: 100%;
  height: 100vh;
  cursor: pointer;
  /* transition: all 0.3s ease-in-out; */
  -webkit-animation: ModalOverlay_fade_i6WfPHZ 1s forwards;
          animation: ModalOverlay_fade_i6WfPHZ 1s forwards;
}

.ModalOverlay_slide_3ar13u3 {
  position: absolute;
  left: -100%;
  -webkit-animation: ModalOverlay_slide_3ar13u3 0.5s forwards;
  animation: ModalOverlay_slide_3ar13u3 0.5s forwards;
}

@-webkit-keyframes ModalOverlay_fade_i6WfPHZ {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ModalOverlay_fade_i6WfPHZ {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes ModalOverlay_slide_3ar13u3 {
  100% { left: 0;
  }
}

@keyframes ModalOverlay_slide_3ar13u3 {
  100% { left: 0;
  }
}