.notifications_wrapper_BRf9H_B {
  position: relative;
  background-color: white;
}

.notifications_button_4ibicXv {
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 15px;
  height: 50px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s
}

.notifications_button_4ibicXv:hover {
  background-color: #f6f6f6;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.notifications_button_4ibicXv[data-active=true] {
  background-color: #f6f6f6;
}

.notifications_counter_3a5VsJE {
  position: absolute;
  top: 8px;
  left: 24px;
  background-color: #cc0000;
  color: white;
  font-size: 9px;
  min-width: 16px;
  height: 16px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 3px;
}