.featureItem_wrapper_2LPdToe {
  padding: 7px 0;
}

.featureItem_checkbox_HRgYN-2 {
  font-weight: 500;
  pointer-events: none
}

.featureItem_checkbox_HRgYN-2 label:before {
  border-radius: 50% !important;
  background-color: #ddd !important;
}

.featureItem_checkbox_HRgYN-2.featureItem_active_24qeeua {
  text-decoration: line-through !important;
  font-weight: 300;
}

.featureItem_checkbox_HRgYN-2.featureItem_active_24qeeua label:before {
  display: none !important;
}

.featureItem_subText_1RAn3sv {
  margin-left: 27px;
  color: #888;
  font-size: 12px;
  font-weight: 300;
}

.featureItem_activeLink_mAv8YDs {
  cursor: pointer;
  pointer-events: default
}

.featureItem_activeLink_mAv8YDs label {
  color: #000000 !important;
  text-decoration: underline;
}

.featureItem_completed_2EOUKO_ {
  pointer-events: none
}

.featureItem_completed_2EOUKO_ label {
  text-decoration: none !important;
}