.imageInfo_name_p9wQz9X {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.imageInfo_name_p9wQz9X > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60%;
}

.imageInfo_name_p9wQz9X .imageInfo_label_3UmWDUl {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.imageInfo_hasPreview_a0_frjH {
  /* text-decoration: underline; */
  border-bottom: 1px dotted;
  cursor: pointer;
}

.imageInfo_imagePreview_1-AnyRo {
  max-width: 200px;
  max-height: 200px;
}

.imageInfo_imageWrapper_60WzJnb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 40px;
  text-align: center;
  margin-right: 10px
}

.imageInfo_imageWrapper_60WzJnb > span {
  height: 16px;
}

.imageInfo_imageWrapper_60WzJnb .imageInfo_label_3UmWDUl {
  font-size: 9px;
  color: #ddd;
}

.imageInfo_popup_EvfBhj9 {
  background-color: #f5f5f5 !important
}

.imageInfo_popup_EvfBhj9:before {
  background-color: #f5f5f5 !important;
}