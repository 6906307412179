.funnelMenuItem_menuItem_2XrG_cS {
  border-radius: 3px;
  border: solid thin transparent;
  color: #666;
  cursor: pointer
}

.funnelMenuItem_menuItem_2XrG_cS:hover {
  color: #394EFF
}

.funnelMenuItem_menuItem_2XrG_cS:hover svg {
  fill: #394EFF;
}

.funnelMenuItem_menuItem_2XrG_cS:hover .funnelMenuItem_actions_27gFKTc {
  opacity: 1;
}

.funnelMenuItem_menuItem_2XrG_cS.funnelMenuItem_active_3vKizBU {
  color: #394EFF;
}

.funnelMenuItem_menuItem_2XrG_cS .funnelMenuItem_disabled_3gj7yZ1 {
  opacity: 0.5;
}

.funnelMenuItem_menuItem_2XrG_cS .funnelMenuItem_iconLabel_3Pt6bJy {
  min-width: 68%;
  max-width: 68%;
}

.funnelMenuItem_menuItem_2XrG_cS .funnelMenuItem_title_3B1Ccfg {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
  margin-top: 1px;
}

.funnelMenuItem_menuItem_2XrG_cS .funnelMenuItem_actions_27gFKTc {
  opacity: 0;
}

.funnelMenuItem_teamIcon_3IOCih7 {
}