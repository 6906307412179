.FilterDuration_wrapper_3tZ-uSP {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.FilterDuration_wrapper_3tZ-uSP input {
  height: 26px;
  max-width: 85px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #888 !important;
}

.FilterDuration_wrapper_3tZ-uSP > div {
  /* &:first-child { */
  margin-right: 10px;
  height: 26px;
  /* padding: 5px !important; */
  /* } */
}

.FilterDuration_label_jod4Vqp {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #888 !important;
  padding: 0px 5px !important;
  line-height: 1.9 !important;
  /* height: 26px;
  display: flex;
  align-items: center;
  justify-content: center; */
}

