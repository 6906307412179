.sideMenuItem_menuItem_3mEGopI {
  border-radius: 3px;
  border: solid thin transparent;
  color: #666;
  cursor: pointer
}

.sideMenuItem_menuItem_3mEGopI:hover .sideMenuItem_iconLabel_2I-sA8l {
  color: #394EFF
}

.sideMenuItem_menuItem_3mEGopI:hover .sideMenuItem_iconLabel_2I-sA8l svg {
  fill: #394EFF;
}

.sideMenuItem_menuItem_3mEGopI:hover .sideMenuItem_actions_1kdWxXI {
  opacity: 1;
}

.sideMenuItem_menuItem_3mEGopI.sideMenuItem_active_2m1w8tf {
  color: #394EFF;
}

.sideMenuItem_menuItem_3mEGopI .sideMenuItem_disabled_30ps4h7 {
  opacity: 0.5;
}

.sideMenuItem_menuItem_3mEGopI .sideMenuItem_iconLabel_2I-sA8l {
  max-width: 85%;
}

.sideMenuItem_menuItem_3mEGopI .sideMenuItem_title_3DGmDzh {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1px;
}

.sideMenuItem_menuItem_3mEGopI .sideMenuItem_actions_1kdWxXI {
  opacity: 0;
}