.alertTypeLabel_wrapper_1vlGAfp {
  background-color: white;
  color: #666;
  border: solid thin #ddd;
}

.alertTypeLabel_alert_1D9zlH_ {
  background: #C3E9EA;
  color: #32888C;
  border: none;
}
