.FilterValueDropdown_wrapper_3R-lPtU {
  border: solid thin #ddd !important;
  border-radius: 3px;
  background-color: white !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 26px;
  width: 100%
}

.FilterValueDropdown_wrapper_3R-lPtU .FilterValueDropdown_right_3p6RotJ {
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  padding: 0;
  background-color: #f6f6f6;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: auto
}

.FilterValueDropdown_wrapper_3R-lPtU .FilterValueDropdown_right_3p6RotJ div {
  /* background-color: red; */
  border-left: solid thin #ddd !important;
  width: 28px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center
}

.FilterValueDropdown_wrapper_3R-lPtU .FilterValueDropdown_right_3p6RotJ div:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.FilterValueDropdown_wrapper_3R-lPtU .FilterValueDropdown_right_3p6RotJ div:hover {
  background-color: #ddd;
}

.FilterValueDropdown_operatorDropdown_4Fzcw-C {
  font-weight: 400;
  /* height: 30px; */
  min-width: 60px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 8px !important;
  font-size: 13px;
  height: 26px;
  /* background-color: rgba(255, 255, 255, 0.8) !important; */
  /* background-color: $gray-lightest !important; */
  /* border: solid thin rgba(34, 36, 38, 0.15) !important; */
  /* border-radius: 4px !important; */
  color: #333 !important;
  font-size: 14px !important
}

.FilterValueDropdown_operatorDropdown_4Fzcw-C.FilterValueDropdown_ui_19d7b2v.FilterValueDropdown_basic_pWp8057.FilterValueDropdown_button_3ipSwkX {
  -webkit-box-shadow: 0 0 0 1px rgba(62, 170, 175,36,38,.35) inset, 0 0 0 0 rgba(62, 170, 175,.15) inset !important;
          box-shadow: 0 0 0 1px rgba(62, 170, 175,36,38,.35) inset, 0 0 0 0 rgba(62, 170, 175,.15) inset !important;
}

.FilterValueDropdown_operatorDropdown_4Fzcw-C {
  /* & input {
    padding: 0 8px !important;
  } */
}