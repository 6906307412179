.tagInput_wrapper_3toqPDM {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 150px
}

.tagInput_wrapper_3toqPDM .tagInput_inputWrapper_240CCPo {
  position: relative;
  margin-right: 5px
}

.tagInput_wrapper_3toqPDM .tagInput_inputWrapper_240CCPo input {
  background-color: white;
  border-radius: 15px !important;
  padding: 4px;
  border: solid thin #ddd;
  padding-left: 25px !important;
  width: 120px;
  height: 25px;
}

.tagInput_wrapper_3toqPDM .tagInput_inputWrapper_240CCPo div {
  z-index: 99;
  background-color: #f6f6f6;
  position: absolute;
  left: 1px;
  top: 1px;
  bottom: 1px;
  width: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}