.funnelSaveModal_modalHeader_12rvTuv {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.funnelSaveModal_cancelButton_1bpQlMb {
  background-color: transparent !important;
  border: solid thin transparent !important;
  color: #394EFF !important;
}

.funnelSaveModal_cancelButton_1bpQlMb:hover {
  background-color: #F6F7FF !important;
}

.funnelSaveModal_applyButton_1Fi1SJ8 {
  background-color: white !important;
  border: solid thin #D0D4F2 !important;
  color: #394EFF !important;
}

.funnelSaveModal_applyButton_1Fi1SJ8:hover {
  background-color: #F6F7FF !important;
}