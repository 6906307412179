.splitButton_left_3LytJtZ {
  margin: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: solid thin #394EFF !important;
  border-right: 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.splitButton_right_3P8iANH {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border: solid thin #394EFF !important;
  padding: 0 12px !important;
}