.Toastify__close-button {
  text-indent: -1000em;
  content: '';
  width: 15px;
  height: 15px;
  display: block;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' class='bi bi-x-lg' viewBox='0 0 16 16' fill='gray-medium'%3E %3Cpath fill-rule='evenodd' d='M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z'/%3E %3Cpath fill-rule='evenodd' d='M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z'/%3E %3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center center !important;
}

.Toastify__toast {
  background-color: white !important;
  color: #888;
  border-left: solid 5px transparent
}

.Toastify__toast.Toastify__toast--default {
  border-color: #394EFF;
}

.Toastify__toast.Toastify__toast--info {
  border-color: #FFFBE5;
}

.Toastify__toast.Toastify__toast--success {
  border-color: #394EFF;
}

.Toastify__toast.Toastify__toast--warning {
  background: #E28940;
}

.Toastify__toast.Toastify__toast--error {
  background: #cc0000;
  border-color: #cc0000;
}