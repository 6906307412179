.FilterAutoCompleteLocal_wrapper_zlfQ0Nd {
  border: solid thin #ddd !important;
  border-radius: 3px;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  width: 100%
}

.FilterAutoCompleteLocal_wrapper_zlfQ0Nd input {
  height: 24px;
  font-size: 13px !important;
  padding: 0 5px !important;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border: solid thin transparent !important;
  width: 100%;
}

.FilterAutoCompleteLocal_wrapper_zlfQ0Nd .FilterAutoCompleteLocal_right_1KQXj46 {
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  padding: 0;
  background-color: #f6f6f6;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px
}

.FilterAutoCompleteLocal_wrapper_zlfQ0Nd .FilterAutoCompleteLocal_right_1KQXj46 div {
  /* background-color: red; */
  border-left: solid thin #ddd !important;
  width: 28px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center
}

.FilterAutoCompleteLocal_wrapper_zlfQ0Nd .FilterAutoCompleteLocal_right_1KQXj46 div:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.FilterAutoCompleteLocal_wrapper_zlfQ0Nd .FilterAutoCompleteLocal_right_1KQXj46 div:hover {
  background-color: #ddd;
}

.FilterAutoCompleteLocal_menu_dQBaJu_ {
  border-radius: 0 0 3px 3px;
  border: solid thin #ddd !important;
  -webkit-box-shadow: 0 2px 2px 0 #ddd;
          box-shadow: 0 2px 2px 0 #ddd;
  /* padding: 20px; */
  background-color: white;
  max-height: 350px;
  overflow-y: auto;
  position: absolute;
  top: 28px;
  left: 0;
  width: 500px;
  z-index: 99;
}

.FilterAutoCompleteLocal_filterItem_2WkfD9X {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 3px;
  /* transition: all 0.4s; */
  margin-bottom: 5px;
  max-width: 100%
}

.FilterAutoCompleteLocal_filterItem_2WkfD9X .FilterAutoCompleteLocal_label_2WMxMrD {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.FilterAutoCompleteLocal_filterItem_2WkfD9X:hover {
  background-color: #f6f6f6;
  /* transition: all 0.2s; */
}