.alertForm_wrapper_3TL4wgV {
  position: relative;
}

.alertForm_content_gtSDZaQ {
  height: calc(100vh - 102px);
  overflow-y: auto
}

.alertForm_content_gtSDZaQ::-webkit-scrollbar {
  width: 2px;
}

.alertForm_content_gtSDZaQ::-webkit-scrollbar-thumb {
  background: transparent;
}

.alertForm_content_gtSDZaQ::-webkit-scrollbar-track {
  background: transparent;
}

.alertForm_content_gtSDZaQ:hover::-webkit-scrollbar-track {
  background: #f3f3f3;
}

.alertForm_content_gtSDZaQ:hover::-webkit-scrollbar-thumb {
  background: #888;
}
