@-webkit-keyframes slideModal_fadeInRight_1rX3VcH {
  0% {
    -webkit-transform:  translate(-100%,0px);
            transform:  translate(-100%,0px);
  }

  100% {
    -webkit-transform:  translate(0,0px);
            transform:  translate(0,0px);
  }
}

@keyframes slideModal_fadeInRight_1rX3VcH {
  0% {
    -webkit-transform:  translate(-100%,0px);
            transform:  translate(-100%,0px);
  }

  100% {
    -webkit-transform:  translate(0,0px);
            transform:  translate(0,0px);
  }
}

@-webkit-keyframes slideModal_fadeInLeft_TGTFnmQ {
  0% {
    -webkit-transform:  translate(1000px,0px);
            transform:  translate(1000px,0px);
  }

  100% {
    -webkit-transform:  translate(0px,0px);
            transform:  translate(0px,0px);
  }
}

@keyframes slideModal_fadeInLeft_TGTFnmQ {
  0% {
    -webkit-transform:  translate(1000px,0px);
            transform:  translate(1000px,0px);
  }

  100% {
    -webkit-transform:  translate(0px,0px);
            transform:  translate(0px,0px);
  }
}

.slideModal_main_3-lJl-p.slideModal_left_1QuighB .slideModal_contentWrapper_20PUefJ {
  left: -200%;
}

.slideModal_main_3-lJl-p.slideModal_right_2NO0W64 .slideModal_contentWrapper_20PUefJ {
  right: -1000px;
}

.slideModal_mainPanel_2bNpnIB {
  -webkit-box-shadow: 5px 0 5px rgba(0,0,0,.05);
          box-shadow: 5px 0 5px rgba(0,0,0,.05);
  z-index: 1;
  height: 100vh;
  overflow-y: auto
}

.slideModal_mainPanel_2bNpnIB::-webkit-scrollbar {
  width: 2px;
}

.slideModal_mainPanel_2bNpnIB::-webkit-scrollbar-thumb {
  background: transparent;
}

.slideModal_mainPanel_2bNpnIB::-webkit-scrollbar-track {
  background: transparent;
}

.slideModal_mainPanel_2bNpnIB:hover::-webkit-scrollbar-track {
  background: #f3f3f3;
}

.slideModal_mainPanel_2bNpnIB:hover::-webkit-scrollbar-thumb {
  background: #888;
}

.slideModal_contentWrapper_20PUefJ {
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 102; /* stay top of the test builer stiky header */
  -webkit-transition: left .2s ease-in-out;
  transition: left .2s ease-in-out
}

.slideModal_contentWrapper_20PUefJ[data-size=big] {
  width: 1006px;
}

.slideModal_contentWrapper_20PUefJ[data-size=middle] {
  width: 500px;
}

.slideModal_contentWrapper_20PUefJ[data-size=lg] {
  width: 400px;
}

.slideModal_contentWrapper_20PUefJ[data-size=small] {
  width: 350px;
}

.slideModal_content_325EYRZ {
  z-index: 1;
  height: 100%;
  background: #fff;
}

.slideModal_detailContent_3H84KCW {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #fff;
  border-left: solid 2px #f6f6f6;
  left: 0;
  height: 100vh;
  overflow-y: auto
}

.slideModal_detailContent_3H84KCW[data-displayed=true] {
  left: 100%;
}

.slideModal_detailContent_3H84KCW::-webkit-scrollbar {
  width: 2px;
}

.slideModal_detailContent_3H84KCW::-webkit-scrollbar-thumb {
  background: transparent;
}

.slideModal_detailContent_3H84KCW::-webkit-scrollbar-track {
  background: transparent;
}

.slideModal_detailContent_3H84KCW:hover::-webkit-scrollbar-track {
  background: #f3f3f3;
}

.slideModal_detailContent_3H84KCW:hover::-webkit-scrollbar-thumb {
  background: #888;
}

.slideModal_header_3Lw4heu {
  font-size: 20px;
  padding: 20px 14px;
}

.slideModal_overlay_127_lYj {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 100%;
  z-index: 102; /* stay top of the test builer stiky header */
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.slideModal_main_3-lJl-p[data-displayed=true] > .slideModal_overlay_127_lYj {
  right: 0;
  opacity: 1;
  -webkit-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.slideModal_main_3-lJl-p[data-displayed=true].slideModal_left_1QuighB > .slideModal_contentWrapper_20PUefJ {
  left: 0;
  -webkit-animation: slideModal_fadeInRight_1rX3VcH ease-in-out 0.3s;
          animation: slideModal_fadeInRight_1rX3VcH ease-in-out 0.3s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-animation-fill-mode:forwards;
          animation-fill-mode:forwards;
}

.slideModal_main_3-lJl-p[data-displayed=true].slideModal_left_1QuighB > .slideModal_closeWrapper_1XPblTA {
  top: 30px;
  right: 30px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.slideModal_main_3-lJl-p[data-displayed=true].slideModal_right_2NO0W64 > .slideModal_contentWrapper_20PUefJ {
  right: 0;
  -webkit-animation: slideModal_fadeInLeft_TGTFnmQ ease-in-out 0.3s;
          animation: slideModal_fadeInLeft_TGTFnmQ ease-in-out 0.3s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-animation-fill-mode:forwards;
          animation-fill-mode:forwards;
}

.slideModal_main_3-lJl-p[data-displayed=true].slideModal_right_2NO0W64 > .slideModal_closeWrapper_1XPblTA {
  top: 30px;
  left: 30px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.slideModal_main_3-lJl-p[data-displayed=true] .slideModal_closeWrapper_1XPblTA {
  top: 30px;
  right: 30px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}