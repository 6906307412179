.siteForm_formWrapper_1klaguq {
  position: relative;
  height: calc(100vh - 59px)
}

.siteForm_formWrapper_1klaguq .siteForm_content_2_H6o20 {
  padding: 0 20px;
}

.siteForm_formWrapper_1klaguq .siteForm_formGroup_1F594kT {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 15px
}

.siteForm_formWrapper_1klaguq .siteForm_formGroup_1F594kT label {
  font-weight: 500;
  margin-bottom: 8px;
}

.siteForm_formWrapper_1klaguq .siteForm_formGroup_1F594kT input {
  background-color: white;
  padding: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.siteForm_formWrapper_1klaguq .siteForm_formGroup_1F594kT input[type=checkbox] {
  margin-right: 10px;
}

.siteForm_formWrapper_1klaguq .siteForm_formGroup_1F594kT .siteForm_controlSubtext_HTshC-c {
  padding-left: 23px;
  font-size:12px;
  color: #888;
  line-height: 14px;
  font-weight: 400;
}

.siteForm_formWrapper_1klaguq .siteForm_footer_3T72ZEy {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  -webkit-box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.08);
}

.siteForm_sampleRate_m9IF8Gy {
  width: 90px;
}

.siteForm_blockIpWarapper_yOCyRZm .siteForm_button_DAiN0Ku {
  padding: 8px 10px;
  border: solid thin #394EFF;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
}

.siteForm_errorMessage_3xsHNBV {
  color: #cc0000;
  font-size: 12px;
  font-weight: 500;
}