.DropdownPlain_dropdown_1Cofjh9 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 4px 8px;
  border-radius: 3px;
  color: #333;
  font-weight: 500;
  background-color: white;
  border: solid thin #ddd
}

.DropdownPlain_dropdown_1Cofjh9:hover {
  background-color: #ddd;
}

.DropdownPlain_dropdownTrigger_2wv9BV9 {
  padding: 4px;
  border-radius: 3px
}

.DropdownPlain_dropdownTrigger_2wv9BV9:hover {
  background-color: #ddd;
}

.DropdownPlain_dropdownIcon_1Hi_ym5 {
  margin-top: 2px;
  margin-left: 3px;
}