.linkStyledInput_input_3WhyhNr {
  border-radius: 3px;
  padding: 3px 5px;
  background-color: white;
  margin-right: 5px;
  min-width: 55%;
  line-height: 15px;
  height: 28px !important
}

.linkStyledInput_input_3WhyhNr[data-fluid] {
  min-width: 90%;
}

.linkStyledInput_input_3WhyhNr:focus {
  border: solid 1px #ddd !important;
  color: #454545 !important;
}

.linkStyledInput_linkStyled_1702uWJ.linkStyledInput_disabled_tKzVgIB {
  color: #ddd;
}

.linkStyledInput_linkStyled_1702uWJ {
  cursor: pointer;
  line-height: 15px;
  padding: 3px 5px;
  border: solid thin transparent;
  height: 24px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: #394EFF;
  text-align: right
}

.linkStyledInput_linkStyled_1702uWJ.linkStyledInput_console_3pMNRpB {
  font-family: 'Menlo', 'Courier' !important;
}