.dateOptionLabel_wrapper_zvJWKJj {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 13px
}

.dateOptionLabel_wrapper_zvJWKJj .dateOptionLabel_divider_31ltiGm {
  margin: 0 2px;
}