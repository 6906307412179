.FilterOperator_operatorDropdown_A1hvACR {
  font-weight: 400;
  height: 26px;
  min-width: 60px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 8px !important;
  font-size: 13px;
  /* background-color: rgba(255, 255, 255, 0.8) !important; */
  background-color: #f6f6f6 !important;
  border: solid thin #e9e9e9 !important;
  border-radius: 4px !important;
  color: #333 !important;
  font-size: 14px !important
}

.FilterOperator_operatorDropdown_A1hvACR.FilterOperator_ui_OzHTAKP.FilterOperator_basic_1yTbx16.FilterOperator_button_3CIPjpW {
  -webkit-box-shadow: 0 0 0 1px rgba(62, 170, 175,36,38,.35) inset, 0 0 0 0 rgba(62, 170, 175,.15) inset !important;
          box-shadow: 0 0 0 1px rgba(62, 170, 175,36,38,.35) inset, 0 0 0 0 rgba(62, 170, 175,.15) inset !important;
}